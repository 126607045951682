/*-------------------------
   Footer Styles
-------------------------*/
.footer-area {
    padding-top: 10px;
}
.footer-top {
    padding-bottom: 70px;
    @media #{$md-layout} {
        padding-bottom: 50px;
    }
}

.footer-social-link {
    ul {
       display: flex;
       align-items: center; 
       justify-content: space-between;
       margin: 0 -5px;
       li {
           a {
                color: var(--color-gray-3);
                font-size: 30px;
                padding: 5px;
                @media #{$md-layout} {
                   font-size: 20px;
                }
                &:hover {
                    color: var(--color-link);
                }
           }
       }
    }
}

.footer-widget {
    margin-bottom: 80px;
    @media #{$sm-layout} {
        margin-bottom: 50px;
    }
    .widget-title {
        font-weight: 700;
    }
    &.border-end {
        border-right-color: var(--color-ghost) !important;
        padding-right: 80px;
        margin-right: 40px;
        @media #{$smlg-device} {
            border: none !important;
            padding-right: 0;
            margin-right: 0;
        }
    }
}

.footer-newsletter {
    .title {
        margin-bottom: 20px;
        letter-spacing: -0.045em;
    }
}

.footer-menu-link {
    ul {
        margin: -8px 0;
    }
    li {
        a {
            color: var(--color-body);
            padding: 8px 0;
            font-size: 18px;
            line-height: 1.5;
            @media #{$md-layout} {
                font-size: 16px;
            }
            &:hover {
                color: var(--color-link);
            }
        }
    }
}

.footer-bottom {
    // border-top: 1px solid var(--color-ghost);
    padding: 25px 0;
}
.footer-copyright {
    @media #{$sm-layout} {
        text-align: center;
        margin-bottom: 10px;
    }
    .copyright-text {
        color: var(--color-gray-1);
        a {
            color: var(--color-gray-1);
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}
.footer-bottom-link {
    text-align: right;
    @media #{$sm-layout} {
        text-align: center;
    }
    ul {
        margin: 0 -20px;
        li {
            padding: 0 20px;
            display: inline-block;
            position: relative;
            &::after {
                content: "";
                height: 4px;
                width: 4px;
                background-color: var(--color-gray-4);
                border-radius: 50%;
                position: absolute;
                top: 53%;
                right: -5px;
                transform: translateY(-50%);
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            a {
                color: var(--color-gray-1);
                &:hover {
                    color: var(--color-link);
                }
            }
        }
    }
}

.footer-dark {
    background-color: var(--color-dark);
    padding-top: 0;
    .footer-bottom {
        border-top-color: var(--color-body);
    }
    .footer-copyright {
        .copyright-text {
            color: var(--color-gray-2);
            a {
                color: var(--color-gray-2);
                &:hover {
                    color: var(--color-white);
                }
            }
        }

    } 
    .footer-bottom-link {
        ul {
            li {
                a {
                    color: var(--color-gray-2);
                    &:hover {
                        color: var(--color-primary);
                    }
                }
                &:after {
                    background-color: var(--color-gray-2);
                }
            }
        }
    }
}